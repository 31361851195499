/**
 * Common methods for Marketing modules
 */
class Helpers
{
    /**
     * Constructor method
     */
    constructor() {
        this.userAgent = navigator.userAgent.toLowerCase();
        this.unavailableAgent = ['Byapps', 'smartskin', 'Cafe24Plus'];
        this.oOsAvailabilityStatus = {
            'android_production_information': ['AI', 'A'],
            'ios_production_information': ['AI', 'I']
        };
    }

    /**
     * Return app URL
     * @return {string}
     */
    getAppUrl(sResource) {
        let sUrl = document.querySelector('script[src*="' + sResource + '.js"]').src
        return this.getUrlOrigin(sUrl);
    }

    getUrlOrigin(sUrl) {
        let oUrl = new URL(sUrl);
        return oUrl.origin;
    }

    getUrlAsObject(sUrl) {
      let aUrl =   sUrl.split('.');
      return {
          subdomain: aUrl[0],
          root_domain: aUrl[1],
          top_level_domain: aUrl[2],
      };
    }

    /**
     * Check if device is Android
     * @return {boolean}
     */
    isAndroid() {
        return /android/.test(this.userAgent);
    }

    /**
     * Check if device is iOS
     * @return {boolean}
     */
    isIos() {
        return /iphone|ipad/.test(this.userAgent);
    }

    /**
     * Check if the app is supported
     * @return {boolean}
     */
    isUnsupportedAgent() {
        if (this.isAndroid() || this.isIos()) {
            let agentList = this.unavailableAgent.join('|');
            let pattern = new RegExp(agentList, 'i');
            return pattern.test(this.userAgent);
        }
        return false;
    }

    createRgbaFromHex(sHexValue, iOpacity = 1) {
        let sParsedHex = parseInt(((sHexValue.indexOf('#') > -1) ? sHexValue.substring(1) : sHexValue), 16);
        let oRgb = {r: sParsedHex >> 16, g: (sParsedHex & 0x00FF00) >> 8, b: (sParsedHex & 0x0000FF)};
        return `rgba(${oRgb.r},${oRgb.g},${oRgb.b}, ${iOpacity  / 100})`;
    }
    /**
     * Open the Play Store or App Store
     * @return {*|void}
     */
    async openAppOrStore(sModule, sMallId, sPlusappUrl, aPlusappSettings) {
        if (this.isAndroid() === true) {
            await this.logAppstoreRedirects('Android', 'install_st', 1, sMallId, sPlusappUrl, sModule);
            let aAndroidInformation = aPlusappSettings.android_production_information;
            this.openAndroidApp(aAndroidInformation['app_id'], aAndroidInformation['google_linkid'], null, true);
        }

        if (this.isIos() === true) {
            await this.logAppstoreRedirects('iOS', 'install_st', 1, this.sMallId, sPlusappUrl, sModule);
            let aIosInformation = aPlusappSettings.ios_production_information;
            return this.openIosApp(aIosInformation['app_id'], aIosInformation['apple_linkid'], null, false, true);
        }
    }

    /**
     * Method to open App
     * @param sModule
     * @param sMallId
     * @param sCurrentUrl
     * @param aPlusappSettings
     * @returns {*}
     */
    openAppOnly(sModule, sMallId, sCurrentUrl, aPlusappSettings) {
        if (this.isAndroid() === true) {
            let aAndroidInformation = aPlusappSettings.android_production_information;
            this.openAndroidApp(aAndroidInformation['app_id'], aAndroidInformation['google_linkid'], sCurrentUrl, false);
        }

        if (this.isIos() === true) {
            let aIosInformation = aPlusappSettings.ios_production_information;
            return this.openIosApp(aIosInformation['app_id'], aIosInformation['apple_linkid'], sCurrentUrl, false, false);
        }
    }

    /**
     * Method to open App Store only
     * @param aPlusappSettings
     */
    openStoreOnly(aPlusappSettings) {
        if (this.isAndroid() === true) {
            let aAndroidInformation = aPlusappSettings.android_production_information;
            this.openAndroidStore(aAndroidInformation['app_id'], aAndroidInformation['google_linkid']);
        }

        if (this.isIos() === true) {
            let aIosInformation = aPlusappSettings.ios_production_information;
            return this.openIosStore(aIosInformation['apple_linkid']);
        }
    }

    /**
     *
     * Open app Store only with logs
     * @param sModule
     * @param sMallId
     * @param sPlusappUrl
     * @param aPlusappSettings
     * @return {*|void}
     */
    async openStoreOnlyWithLogs(sModule, sMallId, sPlusappUrl, aPlusappSettings) {
        if (this.isAndroid() === true) {
            await this.logAppstoreRedirects('Android', 'install_st', 1, sMallId, sPlusappUrl, sModule);
            let aAndroidInformation = aPlusappSettings.android_production_information;
            this.openAppOrPlayStore('android', aAndroidInformation['google_linkid']);
        }

        if (this.isIos() === true) {
            await this.logAppstoreRedirects('iOS', 'install_st', 1, sMallId, sPlusappUrl, sModule);
            let aIosInformation = aPlusappSettings.ios_production_information;
            return this.openAppOrPlayStore('ios', aIosInformation['apple_linkid']);
        }
    }

    /**
     * Open app store or play store
     * @param sOsType
     * @param sLinkId
     */
    openAppOrPlayStore (sOsType, sLinkId) {
        sLinkId = (sOsType === 'android') ? sLinkId : encodeURIComponent(sLinkId);
        location.href = (sOsType === 'android') ? `market://details?id=${sLinkId}` : `itms-apps://itunes.apple.com/app/id${sLinkId}`;
    }


    /**
     * Log the users who installed the application
     * @param sOsType
     * @param sType
     * @param iShopNo
     * @param sMallId
     * @param sDomain
     * @param sModule
     * @param oAdditionalInfo
     * @return {Promise<void>}
     */
    async logAppstoreRedirects(sOsType, sType, iShopNo, sMallId, sDomain = '', sModule = 'recommend_install', oAdditionalInfo = {}) {
        const oThis = this;
        const oRequest = {
            method: 'POST',
            url: `${sDomain}/api/front/${sMallId}/log-appstore-redirect`,
            data: {
                /**
                 * for '$this->ShopNo' to be recognized nest shop_no on data on back-end
                 */
                data: {
                    shop_no : iShopNo,
                },
                domain: oThis.getBaseDomain(sMallId),
                type: sType,
                os : sOsType,
                userAgent: oThis.userAgent,
                module: sModule,
                themes_path: oAdditionalInfo.hasOwnProperty("themes_path") ? oAdditionalInfo.themes_path : '',
                themes_path_title: oAdditionalInfo.hasOwnProperty("themes_path_title") ? oAdditionalInfo.themes_path_title : '',
                request_url: decodeURIComponent(window.location.href)
            }
        };
        await oThis.apiRequest(oRequest);
    }


    /**
     * Method for sending http request
     * @param oRequest
     * @returns {Promise<*>}
     */
    async apiRequest(oRequest) {
        try {
            return await axios({
                method: oRequest.method,
                url: oRequest.url,
                data: oRequest.data,
                params: oRequest.params,
            });
        } catch (error) {
            return error.response;
        }
    }

    /**
     * Method for getting base daomain of mall admin
     * Used for redirecting to specific modules in admin
     * @param sMallId
     * @returns {string}
     */
    getBaseDomain(sMallId = '') {
        let sReferrer = document.referrer;
        let sBaseDomain = sMallId + '.' + CAFE24.ROOT_DOMAIN;
        if (sReferrer !== '') {
            sBaseDomain = (new URL(document.referrer)).hostname;
        }
        return '//' + sBaseDomain;
    }

    /**
     * Get the cookie
     * @param sName
     * @return {string}
     */
    getCookie(sName) {
        let sValue = '; ' + document.cookie;
        let aParts = sValue.split('; ' + sName + '=');
        if (aParts.length === 2) {
            return aParts.pop().split(';').shift();
        }
    }

    /**
     * Set today's cookie
     * @param sName
     * @param sValue
     */
    setTodayCookie(sName, sValue) {
        let sTodayDate = new Date();
        sTodayDate.setDate(sTodayDate.getDate() + 1);
        sTodayDate.setHours(0, 0, 0, 0);
        this.setCookie(sName, sValue, sTodayDate);
    }

    /**
     * Set the cookie
     * @param sName
     * @param sValue
     * @param iExpire
     * @param path
     */
    setCookie(sName, sValue, iExpire, path) {
        let cookieText = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
        cookieText += (iExpire ? '; EXPIRES=' + iExpire.toUTCString() : '');
        cookieText += (path ? '; PATH=' + path : '; PATH=/');
        document.cookie = cookieText;
    }

    /**
     * Check the os availability
     * @param sOs
     * @param aAddServiceInfo
     * @return {boolean}
     */
    checkOsAvailability(sOs, aAddServiceInfo) {
        let bOsNotEmpty = Object.keys(aAddServiceInfo[sOs]).length !== 0 || aAddServiceInfo[sOs] !== null;
        let bOsAvailable = false;
        if (aAddServiceInfo[sOs] !== null) {
            bOsAvailable = this.oOsAvailabilityStatus[sOs].indexOf(aAddServiceInfo['menu_exposure']) >= 0;
        }
        return (bOsAvailable && bOsNotEmpty);
    }

    /**
     * Get the version of IOS
     * @return {number}
     */
    getVersionOfIos() {
        return parseFloat(this.userAgent.substr(this.userAgent.search(/ipad|iphone/), 30).match(/\d+_\d+/)[0].replace('_', '.'));
    }

    /**
     * Open App Store
     * @param sUrlScheme
     * @param sLinkId
     * @param sUrl
     * @param bIsClickEvent
     * @return {*}
     */
    openIosApp(sUrlScheme, sLinkId, sUrl = null, bIsClickEvent = true, bStoreFallback = true) {
        let sAppLink = `${sUrlScheme}://`;
        let sEncodedLink = encodeURIComponent(sLinkId);
        if (sUrl !== null) {
            sAppLink = `${sAppLink}web?url=${encodeURIComponent(sUrl)}`;
        }

        if (bStoreFallback === true) {
            let iClickedAt = +new Date();
            setTimeout(function () {
                if (+new Date() - iClickedAt < 2000) {
                    location.href = `itms-apps://itunes.apple.com/app/id${sEncodedLink}`;
                }
            }, 500);
        }
        setTimeout(function () {
            location.href = sAppLink;
        }, 100);
    }

    /**
     * Method to open iOS App Store only
     * @param sLinkId
     */
    openIosStore(sLinkId) {
        let sEncodedLink = encodeURIComponent(sLinkId);
        location.href = `itms-apps://itunes.apple.com/app/id${sEncodedLink}`;
    }

    /**
     * Open Play Store
     * @param sUrlScheme
     * @param sAppId
     * @param sUrl
     */
    openAndroidApp(sUrlScheme, sAppId, sUrl = null, bStoreFallback = true) {
        let iOpenAt = +new Date();
        let sChrome25 = this.userAgent.search('chrome') > -1 && navigator.appVersion.match(/Chrome\/\d+.\d+/)[0].split("/")[1] > 25;

        if (bStoreFallback === true) {
            setTimeout(function () {
                if (+new Date() - iOpenAt < 2000) {
                    location.href = `market://details?id=${sAppId}`;
                }
            }, 500);
        }

        let sAppLink = `${sUrlScheme}://`;
        if (sUrl !== null) {
            sAppLink = `${sAppLink}web?url=${encodeURIComponent(sUrl)}`;
        }
        setTimeout(function () {
            location.href = sAppLink;
        }, 100);
    }

    /**
     * Method to open Android Store only
     * @param sAppId
     */
    openAndroidStore(sUrlScheme, sAppId) {
        let sAppLink = `${sUrlScheme}://`;
        let sChrome25 = this.userAgent.search('chrome') > -1 && navigator.appVersion.match(/Chrome\/\d+.\d+/)[0].split("/")[1] > 25;
        if (sChrome25 === true) {
            location.href = `intent://web/#Intent;scheme=${sAppLink};package=${sAppId};S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3D${sAppId};end`;
        }
        location.href = `market://details?id=${sAppId}`;
    }

    /**
     * Quick Fix for deep link
     * @return {string}
     */
    getUrlForDeepLink() {
        const URL = location.href;
        const HASH = '#';
        return location.hash.length === 0 ? `${URL}${HASH}` : URL;
    }

    /**
     * check if url parameters has preview_mode (for Iframe preview on Admin side)
     */
    isPreviewMode() {
        const oUrlParams = new URLSearchParams(window.location.search);
        return (oUrlParams.get('PREVIEW_SDE') === '1' && oUrlParams.get('PLUSAPP_MANAGER_ADMIN_URL') !== null);
    }

    /**
     *  convert true string to boolean
     * @param oUrlParams
     * @returns {{[p: string]: unknown}}
     */
    formatMarketSettingsUrlParams(oUrlParamsSettings) {
        return Object.fromEntries(Object.entries(oUrlParamsSettings).map(([sKey, mValue]) => [sKey, mValue === 'true' ? true : mValue]));
    }

    getNewImageDesign(aPopupSettings, sMainId, bIsRedirect) {
        let sInstallOrUseApp = bIsRedirect !== true ? '_install_' : '_use_app_';
        let sImgIntroBGStyle = this.createRgbaFromHex(aPopupSettings['popup_image_intro_bg_color'],
            aPopupSettings['popup_image_intro_transparency']),
            sInstallBtnBGStyle = this.createRgbaFromHex(aPopupSettings['popup_image' + sInstallOrUseApp + 'bg_color'],
                aPopupSettings['popup_image' + sInstallOrUseApp + 'transparency']);
        let aAppLaunchIcon = (this.isAndroid() === true) ? aPopupSettings['app_launch_icon']['android'] : aPopupSettings['app_launch_icon']['ios'];
        let aImageTypes = {
            top: {
                A: `<div id="${sMainId}" class="topTheme1" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}; display: flex" >
                            <div class="logo">
                                <img src="${aAppLaunchIcon}" width="40" height="40" alt="">
                            </div>
                            <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">
                                <strong>${aPopupSettings['popup_image_intro_text']}</strong>
                            </p>
                            <a href="#none" id="btn" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">
                                ${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}
                            </a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" >
                                <img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">
                            </a>
                        </div>
                    </div>`,
                B: `<div id="${sMainId}" class="topTheme2" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}; display: flex">
                            <div class="logo"><img src="${aAppLaunchIcon}" width="24" height="24" alt=""></div>
                            <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}"><strong>${aPopupSettings['popup_image_intro_text']}</strong></p>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" ><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a><!-- 참고: 텍스트색상, 배경색상은 태그에 인라인 스타일로 적용 -->
                        </div>
                    </div>`,
            },
            center: {
                A: `<div id="${sMainId}" class="middleTheme3" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall"  style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
                B: `<div id="${sMainId}" class="middleTheme1" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
                C: `<div id="${sMainId}" class="middleTheme2" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="50" height="50" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" style="color:${aPopupSettings['popup_image_continue_text_color']}">${aPopupSettings['popup_image_continue_text']}<img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a>
                        </div>
                    </div>`,
            },

            bottom: {
                A: `<div id="${sMainId}" class="bottomTheme1" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall"  style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
                B: `<div id="${sMainId}" class="bottomTheme2" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="50" height="50" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall"  style="color:${aPopupSettings['popup_image_continue_text_color']}">${aPopupSettings['popup_image_continue_text']}<img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기"></a>
                        </div>
                    </div>`,
                C: `<div id="${sMainId}" class="bottomTheme3" >
                        <div class="dimmed"></div>
                        <div class="layerBanner" style="background:${sImgIntroBGStyle}">
                            <div class="installBenefit">
                                <p class="message" style="color:${aPopupSettings['popup_image_intro_text_color']}">${aPopupSettings['popup_image_intro_text']}</p>
                                <div class="logo"><img src="${aAppLaunchIcon}" width="80" height="80" alt=""></div>
                            </div>
                            <a href="#none" class="btnInstall" style="color:${aPopupSettings['popup_image' + sInstallOrUseApp + 'text_color']}; background:${sInstallBtnBGStyle}">${aPopupSettings['popup_image' + sInstallOrUseApp + 'text']}</a>
                            <a href="#none" class="btnClose" id="closeRecommendInstall" style="color:${aPopupSettings['popup_image_continue_text_color']}"><img src="//img.echosting.cafe24.com/skin/mobile/app/ico_close.png" width="16" height="16" alt="닫기">${aPopupSettings['popup_image_continue_text']}</a>
                        </div>
                    </div>`,
            },
        }


        return aImageTypes[aPopupSettings['popup_location']][aPopupSettings['popup_image_default_type']];
    }

    /**
     * Popup Button HTML
     * @param Object
     * @return {{button: string, image: string, notification: string}}
     */
    getRedirectPopupHTMLString(oPopupSettings) {
        let iViewPortScale = window.screen.width / Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let oBody = $('body').width();
        let widthImage = 306;
        let widthButton = 15;
        let sFontSize =  'large';

        let oPopupPositionList = {
            top: '5%',
            center: '30%',
            bottom: '69%',
        };
        let oPopupPosition = oPopupSettings.popup_image_type === 'custom'
            ? oPopupPositionList[oPopupSettings.popup_location]
            : oPopupPositionList.center;


        let sPopupImageUrl = oPopupSettings['popup_image_url'];
        if (oPopupSettings.popup_image_type === 'default') {
            sPopupImageUrl = `//img.echosting.cafe24.com/skin/mobile_${oPopupSettings.language_code}/app/img_app_use_banner.jpg`;
        }

        if (iViewPortScale < 1) {
            if (window.screen.width >= 300 || window.innerWidth >= 300) {
                widthImage = oBody - (oBody * iViewPortScale);
                widthButton = widthImage - (widthImage * 0.95);
            }

            if (window.screen.width >= 700 || window.innerWidth >= 700) {
                widthImage = oBody - (oBody * iViewPortScale);
                widthButton = widthImage - (widthImage * 0.95);
            }
            sFontSize = 'xxx-large';
        }

        return {
            image: oPopupSettings.popup_image_type === 'custom' || (oPopupSettings.popup_image_type === 'default' && oPopupSettings.popup_image_default_type === 'default') ? `
                    <div id="mCafe24DirectToApp">
                        <div class="dimmed"></div>
                        <div class="layerImage" style="display: block;">
                            <div class="boxImage" style="display: block; width:80%; top: ${oPopupPosition}">
                                <div class="close">
                                    <a href="#none" class="closeRecommendRedirectToApp btnClose">
                                        <img src="//img.echosting.cafe24.com/skin/mobile/layout/ico_close_white.png" style="width:${widthButton}px; height:${widthButton}px;" alt="close">
                                    </a>
                                </div>
                                <div class="btnDirectToApp">
                                    <a href="#none" class="btnInstall" id="btnPlusappDirectToApp">
                                        <img src="${sPopupImageUrl}" style="width:100%;" alt="앱 설치하기">
                                    </a>
                                </div>
                                <a href="#none" class="btnClose closeRecommendRedirectToApp" style="display: block; margin-top: 0.5rem; text-align: center; color: white; font-size:${sFontSize};"></a>
                            </div>
                        </div>
                    </div>` : this.getNewImageDesign(oPopupSettings, 'mCafe24DirectToApp', true),
            button:`
                    <div id="mCafe24DirectToApp">
                        <div class="dimmed"></div>
                        <div class="layerButton" style="display: block; ${oPopupSettings.popup_location === 'center' ? 'top:50%' : 'top:86%'}">
                            <a href="#none" class="btnDirectToApp btnInstall" id="btnPlusappDirectToApp" style="color:${oPopupSettings.use_button_text_color}; background:${this.createRgbaFromHex(oPopupSettings.use_button_background_color, oPopupSettings.use_button_transparency)};font-size:${oPopupSettings.use_button_font_size + 'px'}">
                                ${oPopupSettings.use_button_title}
                            </a>
                            <a href="#none" class="btnWeb close closeRecommendRedirectToApp btnClose" style="color:${oPopupSettings.continue_button_text_color}; background:${this.createRgbaFromHex(oPopupSettings.continue_button_background_color, oPopupSettings.continue_button_transparency)};font-size:${oPopupSettings.continue_button_font_size + 'px'}">
                                ${oPopupSettings.continue_button_title}
                            </a>
                        </div>
                    </div>`,
            notification: `<div id="mCafe24DirectToApp" class="newAlert">
                                    <div class="dimmed"></div>
                                    <div class="layerBanner" style="background:${this.createRgbaFromHex(oPopupSettings.notification_bg_color, oPopupSettings.notification_transparency)};">
                                        <p class="message" style="color:${oPopupSettings.notification_text_color}; font-size: ${oPopupSettings.notification_font_size + 'px'};">${oPopupSettings.notification_text.replaceAll('\n', '<br>')}</p>
                                        <div class="btnBox">
                                            <a href="#none" class="close btnClose closeRecommendRedirectToApp" style="color:${oPopupSettings.notification_cancel_button_font_color}; background: ${this.createRgbaFromHex(oPopupSettings.notification_cancel_button_bg_color, oPopupSettings.notification_cancel_button_transparency)}">${oPopupSettings.notification_cancel_button}</a>
                                            <a href="#none" class="btnDirectToApp btnInstall" id="btnPlusappDirectToApp" style="color:${oPopupSettings.notification_confirm_button_font_color}; background: ${this.createRgbaFromHex(oPopupSettings.notification_confirm_button_bg_color, oPopupSettings.notification_confirm_button_transparency)}">${oPopupSettings.notification_confirm_button}</a>
                                        </div>
                                    </div>
                                </div>`,
        };
    }

    /**
     * Show Popup on dom/front
     * @param oPopupSettings
     */
    showRedirectPopup(oPopupSettings) {
        const HTML = this.getRedirectPopupHTMLString(oPopupSettings)[oPopupSettings.popup_type];
        document.querySelector('body').insertAdjacentHTML('afterbegin', HTML);
        $('#mCafe24DirectToApp').children().css('display', 'none');
    }

    /**
     * Add Redirect app CSS Script  at the end of <head> tag
     */
    addRedirectCssScript(sAppUrl) {
        const CSS_SCRIPT = `<link rel="stylesheet" href="${sAppUrl}/css/front/recommend-redirect-to-app.css" type="text/css" />`;
        document.querySelector('head').insertAdjacentHTML('beforeend', CSS_SCRIPT);
    }

    /**
     * Encode Element Tags
     * @param sText
     * @returns {*}
     */
    encodeElementTags(sText) {
        return sText.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
    }
}

export default Helpers;
